import React from "react";
import { useRoutes } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import createEmotionCache from "./utils/createEmotionCache";
import { NotificationsProvider } from "./contexts/NotificationsContext";
import { SnackbarProvider } from "notistack";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { reCaptchaSiteKey } from "./config";
// trigger gha 5
const queryClient = new QueryClient();

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet titleTemplate="%s | Aldrin ERP" defaultTitle="Aldrin ERP" />
        {/* @ts-ignore */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiThemeProvider theme={createTheme(theme)}>
            <SnackbarProvider maxSnack={6}>
              <NotificationsProvider>
                <QueryClientProvider client={queryClient}>
                  <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSiteKey}>
                    {content}
                  </GoogleReCaptchaProvider>
                </QueryClientProvider>
              </NotificationsProvider>
            </SnackbarProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
